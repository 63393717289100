// Module
var code = `<span class="token keyword">import</span> Map <span class="token keyword">from</span> <span class="token string">"ol/Map.js"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> TileLayer <span class="token keyword">from</span> <span class="token string">"ol/layer/Tile.js"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> <span class="token constant">OSM</span> <span class="token keyword">from</span> <span class="token string">"ol/source/OSM"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> View <span class="token keyword">from</span> <span class="token string">"ol/View.js"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> ImageTileLayer <span class="token keyword">from</span> <span class="token string">"ol/layer/Tile"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> TileDebug <span class="token keyword">from</span> <span class="token string">"ol/source/TileDebug"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> <span class="token punctuation">{</span> fromLonLat<span class="token punctuation">,</span> <span class="token keyword">get</span> <span class="token keyword">as</span> getProjection <span class="token punctuation">}</span> <span class="token keyword">from</span> <span class="token string">"ol/proj"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> <span class="token punctuation">{</span> Extent<span class="token punctuation">,</span> getWidth <span class="token punctuation">}</span> <span class="token keyword">from</span> <span class="token string">"ol/extent"</span><span class="token punctuation">;</span>

<span class="token keyword">import</span> <span class="token punctuation">{</span> MBTilesRasterSource <span class="token punctuation">}</span> <span class="token keyword">from</span> <span class="token string">"ol-mbtiles"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> TileGrid <span class="token keyword">from</span> <span class="token string">"ol/tilegrid/TileGrid"</span><span class="token punctuation">;</span>

<span class="token keyword">const</span> projExtent <span class="token operator">=</span> <span class="token function">getProjection</span><span class="token punctuation">(</span><span class="token string">"EPSG:3857"</span><span class="token punctuation">)</span><span class="token operator">?.</span><span class="token function">getExtent</span><span class="token punctuation">(</span><span class="token punctuation">)</span> <span class="token keyword">as</span> Extent<span class="token punctuation">;</span>
<span class="token keyword">const</span> baseResolution <span class="token operator">=</span> <span class="token function">getWidth</span><span class="token punctuation">(</span>projExtent<span class="token punctuation">)</span> <span class="token operator">/</span> <span class="token number">256</span><span class="token punctuation">;</span>
<span class="token keyword">function</span> <span class="token function">resolutions</span><span class="token punctuation">(</span>maxZoom<span class="token operator">:</span> <span class="token builtin">number</span><span class="token punctuation">)</span><span class="token operator">:</span> <span class="token builtin">number</span><span class="token punctuation">[</span><span class="token punctuation">]</span> <span class="token punctuation">{</span>
  <span class="token keyword">const</span> r <span class="token operator">=</span> <span class="token punctuation">[</span>baseResolution<span class="token punctuation">]</span><span class="token punctuation">;</span>
  <span class="token keyword">for</span> <span class="token punctuation">(</span><span class="token keyword">let</span> z <span class="token operator">=</span> <span class="token number">1</span><span class="token punctuation">;</span> z <span class="token operator">&lt;=</span> maxZoom<span class="token punctuation">;</span> z<span class="token operator">++</span><span class="token punctuation">)</span> r<span class="token punctuation">.</span><span class="token function">push</span><span class="token punctuation">(</span>r<span class="token punctuation">[</span>r<span class="token punctuation">.</span>length <span class="token operator">-</span> <span class="token number">1</span><span class="token punctuation">]</span> <span class="token operator">/</span> <span class="token number">2</span><span class="token punctuation">)</span><span class="token punctuation">;</span>
  <span class="token keyword">return</span> r<span class="token punctuation">;</span>
<span class="token punctuation">}</span>

<span class="token comment">// Raster MBTiles from</span>
<span class="token comment">// https://www.data.gouv.fr/en/datasets/pyramide-de-tuiles-depuis-la-bd-ortho-r/</span>
<span class="token comment">// 240MB original file</span>

<span class="token comment">// This is a synchronous function</span>
<span class="token keyword">export</span> <span class="token keyword">default</span> <span class="token keyword">function</span> <span class="token punctuation">(</span><span class="token punctuation">)</span> <span class="token punctuation">{</span>
  <span class="token keyword">return</span> <span class="token keyword">new</span> <span class="token class-name">Map</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
    target<span class="token operator">:</span> <span class="token string">"map"</span><span class="token punctuation">,</span>
    layers<span class="token operator">:</span> <span class="token punctuation">[</span>
      <span class="token keyword">new</span> <span class="token class-name">TileLayer</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
        zIndex<span class="token operator">:</span> <span class="token number">20</span><span class="token punctuation">,</span>
        source<span class="token operator">:</span> <span class="token keyword">new</span> <span class="token class-name">TileDebug</span><span class="token punctuation">(</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      <span class="token keyword">new</span> <span class="token class-name">TileLayer</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
        zIndex<span class="token operator">:</span> <span class="token number">0</span><span class="token punctuation">,</span>
        source<span class="token operator">:</span> <span class="token keyword">new</span> <span class="token class-name"><span class="token constant">OSM</span></span><span class="token punctuation">(</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      <span class="token keyword">new</span> <span class="token class-name">ImageTileLayer</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
        zIndex<span class="token operator">:</span> <span class="token number">10</span><span class="token punctuation">,</span>
        opacity<span class="token operator">:</span> <span class="token number">0.5</span><span class="token punctuation">,</span>
        source<span class="token operator">:</span> <span class="token keyword">new</span> <span class="token class-name">MBTilesRasterSource</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
          url<span class="token operator">:</span> <span class="token string">"https://velivole.b-cdn.net/tiles-RGR92UTM40S.mbtiles"</span><span class="token punctuation">,</span>
          attributions<span class="token operator">:</span> <span class="token punctuation">[</span>
            <span class="token string">"IGN / Mapotempo"</span><span class="token punctuation">,</span>
            <span class="token string">"BD Ortho 5m"</span><span class="token punctuation">,</span>
            <span class="token string">"https://www.data.gouv.fr/en/datasets/pyramide-de-tuiles-depuis-la-bd-ortho-r/"</span><span class="token punctuation">,</span>
          <span class="token punctuation">]</span><span class="token punctuation">,</span>
          tileGrid<span class="token operator">:</span> <span class="token keyword">new</span> <span class="token class-name">TileGrid</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
            origin<span class="token operator">:</span> <span class="token punctuation">[</span>projExtent<span class="token punctuation">[</span><span class="token number">0</span><span class="token punctuation">]</span><span class="token punctuation">,</span> projExtent<span class="token punctuation">[</span><span class="token number">2</span><span class="token punctuation">]</span><span class="token punctuation">]</span><span class="token punctuation">,</span>
            <span class="token comment">// These must be known</span>
            extent<span class="token operator">:</span> <span class="token punctuation">[</span>
              <span class="token number">6141145.607336855</span><span class="token punctuation">,</span> <span class="token operator">-</span><span class="token number">2443028.8860581857</span><span class="token punctuation">,</span> <span class="token number">6216858.872510456</span><span class="token punctuation">,</span>
              <span class="token operator">-</span><span class="token number">2366876.107971946</span><span class="token punctuation">,</span>
            <span class="token punctuation">]</span><span class="token punctuation">,</span>
            minZoom<span class="token operator">:</span> <span class="token number">9</span><span class="token punctuation">,</span>
            resolutions<span class="token operator">:</span> <span class="token function">resolutions</span><span class="token punctuation">(</span><span class="token number">16</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
          <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
        <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
    <span class="token punctuation">]</span><span class="token punctuation">,</span>
    view<span class="token operator">:</span> <span class="token keyword">new</span> <span class="token class-name">View</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
      center<span class="token operator">:</span> <span class="token function">fromLonLat</span><span class="token punctuation">(</span><span class="token punctuation">[</span><span class="token number">55.47437</span><span class="token punctuation">,</span> <span class="token operator">-</span><span class="token number">21.08468</span><span class="token punctuation">]</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      zoom<span class="token operator">:</span> <span class="token number">9</span><span class="token punctuation">,</span>
    <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
  <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">;</span>
<span class="token punctuation">}</span>
`;
// Exports
export default code;